import React from "react";
import Banner from "../components/sections/banner";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import CenteredContent from "../components/sections/centered-content";

const NotFoundPage = ({
  data: {
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  const banner = {
    name: "error banner",
    background: themeSettings.errorPageBanner,
    subTitle: themeSettings.errorPageSubTitle,
    title: themeSettings.errorPageTitle,
  };

  const centeredContent = {
    name: "error centered content",
    title: themeSettings.errorPageCenteredContentTitle,
    subTitle: themeSettings.errorPageCenteredContentSubTitle,
    content: themeSettings.errorPageCenteredContentContent,
  };

  return (
    <>
      <Helmet>
        <title>
          {themeSettings.errorPageMetaTitle} - {themeSettings.siteMetaTitle}
        </title>
      </Helmet>
      <Banner data={banner} />
      <CenteredContent data={centeredContent} />
    </>
  );
};

export const pageQuery = graphql`
  query ErrorPage {
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          errorPageMetaTitle
          errorPageBanner {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
          }
          errorPageSubTitle
          errorPageTitle
          errorPageCenteredContentTitle
          errorPageCenteredContentSubTitle
          errorPageCenteredContentContent
        }
      }
    }
  }
`;

export default NotFoundPage;
